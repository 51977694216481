<template>
  <div>
    <div
      id="offcanvasChapters"
      class="offcanvas offcanvas-end offcanvas-end--filters d-flex"
      :class="{ show: visible }"
      :style="{ visibility: visible ? 'visible' : 'hidden' }"
      tabindex="-1"
      aria-labelledby="offcanvasFiltersLabel"
    >
      <div class="offcanvas-header">
        <h3
          id="offcanvasResearchLineLabel"
          class="offcanvas-title"
        >
          <span class="btn btn-icon btn-icon rounded-circle btn-flat-secondary bg-light-secondary me-2"><i data-feather="settings" /></span>
          Traces
        </h3>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="closeOffcanva"
        />
      </div>
      <div class="offcanvas-body">
        <div class="mb-1 offcanvas-body--view--title">
          <p>Researcher<br>
            <strong>{{ user.full_name ? user.full_name : user.name }}</strong></p>
        </div>
        <hr>
        <a
          ref="addTrace"
          v-b-toggle="`collapseTrace`"
          class="btn btn-sm btn-dark"
          data-bs-toggle="collapse"
          role="button"
          aria-expanded="false"
          aria-controls="collapseAddTrace"
        ><i data-feather="plus" /> Add trace</a>
        <b-collapse
          :id="`collapseTrace`"
        >
          <div
            id="collapseAddTrace"
          >
            <div class="mb-1 mt-2">
              <label
                for=""
                class="form-label"
              >Content</label>
              <textarea
                id=""
                v-model="note"
                name=""
                cols=""
                rows="5"
                class="form-control"
              />
            </div>
            <hr>
            <div class="mb-1">
              <p><strong>Typology</strong></p>
              <v-select
                v-model="type"
                label="name"
                :options="typologies"
                :get-option-key="option => option.id"
              />
            </div>
            <hr>
            <div
              class="mb-1"
            >
              <p><strong>File</strong></p>
              <input
                :ref="`trace-file-new`"
                type="file"
                @change="setFile(null)"
              >
            </div>
            <div class="mb-1">
              <button
                class="btn btn-sm btn-success"
                @click="saveTrace('', note, file, type)"
              >
                Save trace
              </button>
            </div>
          </div>
        </b-collapse>
        <hr>
        <template v-if="!loading">
          <div
            v-for="trace, index in user.traces"
            :key="trace.id"
            class="card"
          >
            <div class="card-body">
              <div class="mb-1">
                <p>Date<br>
                  <strong>{{ trace.date }}</strong></p>
              </div>
              <div class="mb-1">
                <p>Created by<br>
                  <strong>{{ trace.created_by }}</strong></p>
              </div>
              <hr>
              <div class="mb-1">
                <p><strong>Content</strong></p>
                <div
                  v-if="noteEdit == null || noteEdit != trace.id"
                  v-html="trace.note"
                />
                <textarea
                  v-else
                  v-model="trace.note"
                  rows="5"
                  class="form-control"
                />
              </div>
              <hr>
              <div class="mb-1">
                <p><strong>Typology</strong></p>
                <div
                  v-if="noteEdit == null || noteEdit != trace.id"
                >
                  {{ trace.typology ? trace.typology.name : '-' }}
                </div>
                <v-select
                  v-else
                  v-model="trace.typology"
                  label="name"
                  :options="typologies"
                  :get-option-key="option => option.id"
                />
              </div>
              <hr>
              <div
                class="mb-1"
              >
                <p><strong>File</strong></p>
                <a
                  v-if="(noteEdit == null || noteEdit != trace.id) && trace.file"
                  :href="trace.file.url"
                  target="_blank"
                  class=" me-1"
                ><i data-feather="eye" />{{ trace.file.name }}</a>
                <div v-else-if="(noteEdit == null || noteEdit != trace.id) && !trace.file">
                  -
                </div>
                <input
                  v-else
                  :ref="`trace-file`"
                  type="file"
                  @change="setFile(index)"
                >
              </div>
              <div class="mb-1">
                <div class="row">
                  <div class="col">
                    <a
                      v-if="noteEdit == null || noteEdit != trace.id"
                      class="btn btn-dark d-grid w-100 btn-sm"
                      @click="noteEdit = trace.id"
                    >Edit trace</a>
                    <a
                      v-if="noteEdit != null && noteEdit == trace.id"
                      class="btn btn-success d-grid w-100 btn-sm"
                      @click="saveTrace(trace.id, trace.note, trace.file, trace.typology)"
                    >Save trace</a>
                  </div>
                  <div class="col">
                    <a
                      class="btn btn-danger d-grid w-100 btn-sm"
                      @click="deleteNote(trace.id)"
                    >Delete</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div
      v-if="visible"
      class="offcanvas-backdrop fade"
      :class="{ show: visible }"
      @click="closeOffcanva"
    />
  </div>
  <!-- -->
</template>
<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Vue from 'vue'

export default {
  components: {
    BCollapse,
    vSelect,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      note: '',
      noteEdit: null,
      file: {},
      type: null,
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      visible: 'modals/userTraces',
      traces: 'users/traces',
      typologies: 'traceTypologies/items',
    }),
  },
  watch: {
    async user() {
      this.loading = true
      if (!this.user.traces || this.user.traces.length == 0) {
        this.$store.dispatch('users/clearTraces')
        await this.$store.dispatch('users/fetchUserTraces', this.user.id)
        this.user.traces = this.traces
      }
      this.loading = false
    },
  },
  mounted() {
    if (this.typologies.length === 0) {
      this.$store.dispatch('traceTypologies/fetch')
    }
  },
  methods: {
    closeOffcanva() {
      this.$store.dispatch('modals/toggleUserTraces', false)
    },
    async saveTrace(id, note, file, type) {
      try {
        let url = null
        if (id) {
          url = `${Vue.prototype.$groupUrl}/internal-notes/edit/${id}`
        } else {
          url = `${Vue.prototype.$groupUrl}/internal-notes/create`
        }

        const resp = await Vue.prototype.$http.post(url, {
          note,
          userId: this.user.user_id ?? this.user.id,
          trace_url: this.$route.name,
          trace_typology_id: type ? type.id : null,
          file,
        })

        if (resp.status >= 200) {
          Vue.swal('Trace modified successfully!', '', 'success')

          if (!id) {
            this.user.traces.push(resp.data.data)
            this.$refs.addTrace.click()
          } else {
            this.noteEdit = null
          }

          this.note = ''

          Vue.prototype.$saveFiles([file])
        } else {
          Vue.swal('Error on modifying the trace.', 'Please, try it again', 'error')
        }
      } catch (e) {
        Vue.swal('Error on modifying the trace.', 'Please, try it again', 'error')
      }
    },
    async deleteNote(id) {
      try {
        const resp = await Vue.prototype.$http.delete(`${Vue.prototype.$groupUrl}/internal-notes/delete/${id}`)
        if (resp.status === 200) {
          Vue.swal('Trace deleted successfully!', '', 'success')
          this.user.traces.splice(this.user.traces.findIndex(x => x.id === id), 1)
        } else {
          Vue.swal('Error on deleting the trace.', 'Please, try it again', 'error')
        }
      } catch (e) {
        Vue.swal('Error on deleting the trace.', 'Please, try it again', 'error')
      }
    },
    setFile(index) {
      if (index !== null) {
        const file = this.$refs['trace-file'][0].files[0]
        this.user.traces[index].file = {
          weight: (file.size / 1024 / 1024).toFixed(2),
          extension: file.type,
          created_at: 'Pending to save...',
          file,
          name: file.name,
          original_name: file.name,
          url: URL.createObjectURL(file),
          description: '-',
          to: '',
        }
      } else {
        const file = this.$refs['trace-file-new'].files[0]
        this.file = {
          weight: (file.size / 1024 / 1024).toFixed(2),
          extension: file.type,
          created_at: 'Pending to save...',
          file,
          name: file.name,
          original_name: file.name,
          url: URL.createObjectURL(file),
          description: '-',
          to: '',
        }
      }
    },
  },
}
</script>
